<template>
  <div class="overview">
    <v-card class="overview">
      <svg id="plan" viewBox="0 0 947 847" preserveAspectRatio="xMidYMin meet" style="">
        <defs>
          <pattern id="bg" patternUnits="userSpaceOnUse" x="0" y="0" width="947" height="847">
            <image href="~@/assets/plan.gif" x="0" y="25" width="947" height="821"/>
          </pattern>
        </defs>
        <!-- background -->
        <path d="M 0 0 L 0 847 L 947 847 L 947 0 " fill="url(#bg)"></path>
        <!-- windows/glassdoors -->
        <!--path data-id="door-roof" v-bind:class="{ open: isRoofDoorOpened }" d="M 580 135 L 700 135 L 700 80 L 580 80 Z"></path-->
        <path data-id="balcony-livingroom" d="M 634 672 L 840 672 L 805 657 L 619 657 Z"
          v-bind:class="{ open: isLivingroomBalconyOpened, outdated: isLivingroomBalconyOutdated }" />
        <path data-id="window-kitchen" d="M 440 672 L 510 672 L 505 660 L 445 660 Z"
          v-bind:class="{ open: isKitchenWindowOpened, outdated: isKitchenWindowOutdated }" />
        <path data-id="balcony-bedroom" d="M 340 672 L 183 672 L 220 652 L 355 652 Z"
          v-bind:class="{ open: isBedroomBalconyOpened, outdated: isBedroomBalconyOutdated }" />
        <path data-id="window-utilroom" d="M 897 218 L 899 251 L 865 273 L 864 243 Z"
          v-bind:class="{ open: isUtilroomWindowOpened, outdated: isUtilroomWindowOutdated }" />
        <path data-id="window-office" d="M 48 265 L 44 330 L 76 343 L 78 284 Z"
          v-bind:class="{ open: isOfficeWindowOpened, outdated: isOfficeWindowOutdated }" />
        <path data-id="window-paulroom" d="M 56 118 L 52 180 L 83 203 L 86 147 Z"
          v-bind:class="{ open: isPaulroomWindowOpened, outdated: isPaulroomWindowOutdated }" />
        <!-- main/inner doors -->
        <path data-id="door-entrance" d="M 580 182 L 626 182 L 609 221 L 568 221 Z"
          v-bind:class="{ open: isEntranceDoorOpened, outdated: isEntranceDoorOutdated }" />
        <path data-id="door-office" d="M 333 304 L 332 350 L 312 326 L 314 277 Z"
          v-bind:class="{ open: isOfficeDoorOpened, outdated: isOfficeDoorOutdated }" />
        <path data-id="door-livingroom" d="M 547 411 L 540 411 L 540 377 L 549 363 L 550 407 Z"
          v-bind:class="{ open: isLivingroomDoorOpened, outdated: isLivingroomDoorOutdated }" />
        <path data-id="door-utilroom" d="M 659 215 L 660 265 L 637 293 L 636 250 Z"
          v-bind:class="{ open: isUtilroomDoorOpened, outdated: isUtilroomDoorOutdated }" />
        <path data-id="door-paulroom" d="M 320 230 L 338 265 L 384 265 L 372 230 Z"
          v-bind:class="{ open: isPaulroomDoorOpened, outdated: isPaulroomDoorOutdated }" />
        <path data-id="door-bedroom" d="M 342 398 L 325 382 L 378 382 L 389 398 Z"
          v-bind:class="{ open: isBedroomDoorOpened, outdated: isBedroomDoorOutdated }" />
        <path data-id="door-bathroom" d="M 423 230 L 429 265 L 476 265 L 476 230 Z"
          v-bind:class="{ open: isBathroomDoorOpened, outdated: isBathroomDoorOutdated }" />
        <!-- water leak sensors -->
<!--        <text data-id="leak-bathroom" x="403" y="170" class="fas"-->
<!--          v-bind:class="{ leak: isBathroomWaterLeak }">&#xf043;</text>-->
        <text data-id="leak-kitchen" x="515" y="610" class="fas"
          v-bind:class="{ leak: isKitchenWaterLeak }">&#xf043;</text>
        <!-- temperature/humidity sensors -->
        <text data-id="temperature-paulroom" x="190" y="155"
          v-bind:class="{ outdated: isPaulroomTemperatureOutdated }"
        >
          {{ paulroomTemperature }}
        </text>
        <text data-id="humidity-paulroom" x="210" y="190"
          v-bind:class="{ outdated: isPaulroomHumidityOutdated }"
        >
          {{ paulroomHumidity }}
        </text>
        <text data-id="temperature-office" x="135" y="315"
          v-bind:class="{ outdated: isOfficeTemperatureOutdated }"
        >
          {{ officeTemperature }}
        </text>
        <text data-id="humidity-office" x="155" y="350"
          v-bind:class="{ outdated: isOfficeHumidityOutdated }"
        >
          {{ officeHumidity }}
        </text>
        <text data-id="temperature-bedroom" x="220" y="510"
          v-bind:class="{ outdated: isBedroomTemperatureOutdated }"
        >
          {{ bedroomTemperature }}
        </text>
        <text data-id="humidity-bedroom" x="220" y="545"
          v-bind:class="{ outdated: isBedroomHumidityOutdated }"
        >
          {{ bedroomHumidity }}
        </text>
        <text data-id="temperature-livingroom" x="660" y="510"
          v-bind:class="{ outdated: isLivingroomTemperatureOutdated }"
        >
          {{ livingroomTemperature }}
        </text>
        <text data-id="humidity-livingroom" x="680" y="545"
          v-bind:class="{ outdated: isLivingroomHumidityOutdated }"
        >
          {{ livingroomHumidity }}
        </text>
        <text data-id="temperature-kitchen" x="420" y="530"
          v-bind:class="{ outdated: isKitchenTemperatureOutdated }"
        >
          {{ kitchenTemperature }}
        </text>
        <text data-id="humidity-kitchen" x="442" y="565"
          v-bind:class="{ outdated: isKitchenHumidityOutdated }"
        >
          {{ kitchenHumidity }}
        </text>
        <text data-id="temperature-balcony" x="432" y="740"
          v-bind:class="{ outdated: isBalconyTemperatureOutdated }"
        >
          {{ balconyTemperature }}
        </text>
        <text data-id="humidity-balcony" x="453" y="775"
          v-bind:class="{ outdated: isBalconyHumidityOutdated }"
        >
          {{ balconyHumidity }}
        </text>
        <text data-id="temperature-hall" x="390" y="320"
          v-bind:class="{ outdated: isHallTemperatureOutdated }"
        >
          {{ hallTemperature }}
        </text>
        <text data-id="humidity-hall" x="410" y="355"
          v-bind:class="{ outdated: isHallHumidityOutdated }"
        >
          {{ hallHumidity }}
        </text>
        <text data-id="temperature-bathroom" x="410" y="165"
          v-bind:class="{ outdated: isBathroomTemperatureOutdated }"
        >
          {{ bathroomTemperature }}
        </text>
        <text data-id="humidity-bathroom" x="430" y="200"
          v-bind:class="{ outdated: isBathroomHumidityOutdated }"
        >
          {{ bathroomHumidity }}
        </text>
        <text data-id="temperature-utilroom" x="690" y="252"
          v-bind:class="{ outdated: isUtilroomTemperatureOutdated }"
        >
          {{ utilroomTemperature }}
        </text>
        <text data-id="humidity-utilroom" x="710" y="287"
          v-bind:class="{ outdated: isUtilroomHumidityOutdated }"
        >
          {{ utilroomHumidity }}
        </text>
        <!-- presence sensors -->
        <text data-id="presence-office" x="270" y="300" class="fas"
          v-bind:class="{ active: isOfficeActive, outdated: isOfficeActiveOutdated }">&#xf554;</text>
        <text data-id="presence-entrance" x="570" y="260" class="fas"
          v-bind:class="{ active: isEntranceActive, outdated: isEntranceActiveOutdated }">&#xf554;</text>
        <text data-id="presence-kitchen" x="425" y="635" class="fas"
          v-bind:class="{ active: isKitchenActive, outdated: isKitchenActiveOutdated }">&#xf554;</text>
        <text data-id="presence-paulroom" x="340" y="200" class="fas"
          v-bind:class="{ active: isPaulroomActive, outdated: isPaulroomActiveOutdated }">&#xf554;</text>
        <text data-id="presence-livingroom" x="575" y="475" class="fas"
          v-bind:class="{ active: isLivingroomActive, outdated: isLivingroomActiveOutdated }">&#xf554;</text>
        <text data-id="presence-bedroom" x="160" y="440" class="fas"
          v-bind:class="{ active: isBedroomActive, outdated: isBedroomActiveOutdated }">&#xf554;</text>
        <text data-id="presence-utilroom" x="810" y="281" class="fas"
          v-bind:class="{ active: isUtilroomActive, outdated: isUtilroomActiveOutdated }">&#xf554;</text>
<!--        <text data-id="presence-roof" x="590" y="145" class="fas fa-user-secret"-->
<!--          v-bind:class="{ active: isRoofActive }">&#xf21b;</text>-->
        <!-- lights -->
        <text data-id="light-hall_vitrine" x="425" y="402" class="fas"
          v-on:click="onHallVitrineLightClick()"
          v-bind:class="{ off: isHallLightOff }"
        >
          &#xf0eb;
          <!--
          <title>{{ $store.state.i18n.s[isHallLightOff ? 'Turn on' : 'Turn off'] }}</title>
          -->
        </text>
        <text data-id="light-mi_wireless_cleaner" x="565" y="640" class="fas"
          v-on:click="onMiWirelessCleanerClick()"
          v-bind:class="{ off: isMiWirelessCleanerOff }"
        >
          &#xf51a;
        </text>
        <text data-id="light-balcony_light" x="40" y="730" class="fas"
          v-on:click="onBalconyLightClick()"
          v-bind:class="{ off: isBalconyLightOff }"
        >
          &#xf0eb;
        </text>
        <text data-id="light-aquarium" x="805" y="415" class="fas"
          v-on:click="onAquariumClick()"
          v-bind:class="{ off: isAquariumOff }"
        >
          &#xf578;
        </text>
        <!-- WLAN devices -->
        <!-- <text data-id="wlanDevice-grundig_tv" x="345" y="540" class="fas"
          v-bind:class="{ off: isTvOnline }"
        >
          &#xf26c;
        </text> -->
      </svg>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'overview',
  methods: {
    onHallVitrineLightClick () {
      this.$store.dispatch('db/switchLight', {
        place: 'hall_vitrine'
      })
    },
    onMiWirelessCleanerClick () {
      this.$store.dispatch('db/switchLight', {
        place: 'mi_wireless_cleaner'
      })
    },
    onBalconyLightClick () {
      this.$store.dispatch('db/switchLight', {
        place: 'balcony_light'
      })
    },
    onAquariumClick () {
      this.$store.dispatch('db/switchLight', {
        place: 'aquarium'
      })
    }
  },
  computed: {
    ...mapGetters([
      //  === Door and window ===
      // 'isRoofDoorOpened',
      'isOfficeWindowOpened',
      'isOfficeDoorOpened',
      'isLivingroomDoorOpened',
      'isLivingroomBalconyOpened',
      'isKitchenWindowOpened',
      'isBedroomBalconyOpened',
      'isBedroomDoorOpened',
      'isUtilroomWindowOpened',
      'isUtilroomDoorOpened',
      'isBathroomDoorOpened',
      'isPaulroomDoorOpened',
      'isPaulroomWindowOpened',
      'isEntranceDoorOpened',
      // === Activity presence ===
      'isEntranceActive',
      'isOfficeActive',
      'isKitchenActive',
      'isPaulroomActive',
      'isLivingroomActive',
      'isBedroomActive',
      'isUtilroomActive',
      'isRoofActive',
      // === Water leakage ===
      // 'isBathroomWaterLeak',
      'isKitchenWaterLeak',
      // === Temperature and humidity ===
      'kitchenTemperature',
      'kitchenHumidity',
      'balconyTemperature',
      'balconyHumidity',
      'livingroomTemperature',
      'livingroomHumidity',
      'bedroomTemperature',
      'bedroomHumidity',
      'officeTemperature',
      'officeHumidity',
      'paulroomTemperature',
      'paulroomHumidity',
      'hallTemperature',
      'hallHumidity',
      'bathroomTemperature',
      'bathroomHumidity',
      'utilroomTemperature',
      'utilroomHumidity',
      // === Lights ===
      'isHallLightOff',
      'isMiWirelessCleanerOff',
      'isBalconyLightOff',
      'isAquariumOff',
      // === Devices ===
      // 'isTvOnline',

      // === OUTDATED flags ===
      'isOfficeWindowOutdated',
      'isOfficeDoorOutdated',
      'isLivingroomDoorOutdated',
      'isLivingroomBalconyOutdated',
      'isKitchenWindowOutdated',
      'isBedroomBalconyOutdated',
      'isBedroomDoorOutdated',
      'isUtilroomWindowOutdated',
      'isUtilroomDoorOutdated',
      'isBathroomDoorOutdated',
      'isPaulroomDoorOutdated',
      'isPaulroomWindowOutdated',
      'isPaulroomActiveOutdated',
      'isLivingroomActiveOutdated',
      'isBedroomActiveOutdated',
      'isUtilroomActiveOutdated',
      'isRoofActiveOutdated',
      'isEntranceDoorOutdated',
      'isEntranceActiveOutdated',
      'isOfficeActiveOutdated',
      'isKitchenActiveOutdated',
      'isKitchenTemperatureOutdated',
      'isKitchenHumidityOutdated',
      'isBalconyTemperatureOutdated',
      'isBalconyHumidityOutdated',
      'isLivingroomTemperatureOutdated',
      'isLivingroomHumidityOutdated',
      'isBedroomTemperatureOutdated',
      'isBedroomHumidityOutdated',
      'isOfficeTemperatureOutdated',
      'isOfficeHumidityOutdated',
      'isPaulroomTemperatureOutdated',
      'isPaulroomHumidityOutdated',
      'isHallTemperatureOutdated',
      'isHallHumidityOutdated',
      'isBathroomTemperatureOutdated',
      'isBathroomHumidityOutdated',
      'isUtilroomTemperatureOutdated',
      'isUtilroomHumidityOutdated'
    ].reduce(
      (obj, k) => Object.assign(obj, { [k]: `db/${k}` }),
      { /* initial mapGetters object */ }
    ))
  }
}
</script>

<style scoped lang="scss">

$color-red: #F50057;
$color-purple: #ab47bc;
$color-green: #00E676;
$color-blue: #2196F3;
$color-yellow: #FFFF00;
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey-light: #E0E0E0;
$color-grey-dark: #323232;
$color-orange-light: #FF6E40;
$color-orange-dark: #FF3D00;

@media all and (max-width: 480px) {
  .overview svg {
    padding-top: 20%;
    text {
      font-size: 36px !important;
    }
  }
}

.overview {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 70px);
  padding: 10px;
  overflow: hidden;

  svg {
    margin-top: -10px;
    width: 100%;
    height: 100%;

    [data-id^='light-'] {
      cursor: pointer;
      fill: $color-yellow;
      text-shadow: 0 0 15px $color-white;
      &.off {
        fill: $color-white;
        opacity: 0.7;
        text-shadow: none;
        &:hover {
          text-shadow: 0 0 15px $color-white;
        }
      }
      &:hover {
        text-shadow: none;
      }
    }

    [data-id^='wlanDevice-'] {
      fill: $color-blue;
      opacity:1;
      text-shadow: 0 0 6px $color-white;
      &.off {
        fill: $color-white;
        opacity: 0.4;
      }
    }

    [data-id^='window-'],
    [data-id^='door-'],
    [data-id^='balcony-'] {
      cursor: default;
      stroke-width: 2px;
      stroke: $color-green;
      fill: rgba($color-green, .05);
      &.open {
        stroke: $color-red;
      }
      &.outdated {
        stroke: $color-orange-light !important;
        fill: rgba($color-orange-light, .3) !important;
      }
    }

    [data-id^='door-'] {
       &:not(.open) {
         fill: $color-grey-light;
       }
    }

    [data-id^='presence-'],
    [data-id^='leak-'] {
      cursor: default;
      fill: $color-green;
      opacity: 0.5;
      text-shadow: none;
      &.active, &.leak, &.off {
        fill: $color-red;
        text-shadow: 0 0 20px $color-white;
        opacity: 1;
      }
      &.outdated {
        opacity: 1;
      }
    }

    [data-id='presence-roof'] {
      opacity: 0;
    }

    text {
      font-size: 32px;
      fill: $color-white;

      &.outdated {
        text-decoration: line-through;
        fill: $color-orange-light !important;
      }

      &[data-id$='temperature-balcony'],
      &[data-id='humidity-balcony'],
      &[data-id$='temperature-bathroom'],
      &[data-id='humidity-bathroom'],
      &[data-id='temperature-utilroom'],
      &[data-id='humidity-utilroom'] {
        fill: $color-grey-dark;
        .outdated {
          fill: $color-orange-dark !important;
        }
      }
    }
  }
}
</style>
