import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const [M, A] = [
  { // MUTATIONS
    SET_USER: 'SET_USER',
    SET_ALLOWED_UIDS: 'SET_ALLOWED_UIDS'
  },
  { // ACTIONS
    FETCH_ALLOWED_UIDS: 'fetchAllowedUids',
    SET_USER: 'setUser',
    LOGOUT: 'logout'
  }
]

export default {
  namespaced: true,
  state: {
    allowedUids: [],
    user: {
      uid: null,
      email: null,
      displayName: null,
      photoURL: null
    }
  },
  getters: {
    isAuthenticated: state => state.user && state.user.uid &&
      state.allowedUids.includes(state.user.uid)
  },
  mutations: {
    [M.SET_USER]: (state, user) => { state.user = user },
    [M.SET_ALLOWED_UIDS]: (state, uids) => { state.allowedUids = uids }
  },
  actions: {
    [A.SET_USER]: ({ commit }, user) => commit(M.SET_USER, user),
    [A.FETCH_ALLOWED_UIDS]: async ({ commit }) => {
      const db = firebase.database()

      let allowedUids
      try {
        const allowed = (await db.ref('/security/allowedEmails').once('value')).val()
        allowedUids = Object.keys(allowed || {})
      } catch (ex) {
        // console.log(ex)
        allowedUids = []
      }
      // console.log(`Allowed UIDs: ${allowedUids}`)

      commit(M.SET_ALLOWED_UIDS, allowedUids)
    },
    [A.LOGOUT]: async ({ commit }) => {
      await firebase.auth().signOut()
      commit(M.SET_ALLOWED_UIDS, [])
      commit(M.SET_USER, {})
    }
  }
}
