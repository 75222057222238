import Vue from 'vue'
import VueRouter from 'vue-router'
import Overview from '../views/Overview.vue'
import store from '../store'

Vue.use(VueRouter)

const ROUTE = {
  NOT_FOUND: '/notfound',
  AUTH: '/auth',
  DEFAULT: '/overview'
}

const routes = [
  {
    path: ROUTE.NOT_FOUND,
    name: 'notfound',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: ROUTE.AUTH,
    name: 'auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: ROUTE.DEFAULT,
    name: 'overview',
    component: Overview
  },
  {
    path: '/log',
    name: 'log',
    component: () => import('../views/Log.vue')
  },
  {
    path: '/wlan',
    name: 'wlan',
    component: () => import('../views/WlanDevices.vue')
  },
  {
    path: '/temperature',
    name: 'temperature',
    component: () => import('../views/charts/Temperature.vue')
  },
  {
    path: '/humidity',
    name: 'humidity',
    component: () => import('../views/charts/Humidity.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (
    !isAuthenticated &&
    !router.initialPath &&
    to.path !== ROUTE.AUTH &&
    routes.filter(r => r.path === to.path).length
  ) {
    router.initialPath = to.path
  }
  if ((isAuthenticated && to.path !== ROUTE.AUTH) ||
      (!isAuthenticated && to.path === ROUTE.AUTH)) {
    if (to.matched.length === 0) {
      next({ path: ROUTE.NOT_FOUND })
    } else {
      next()
    }
  } else {
    next({ path: isAuthenticated ? ROUTE.DEFAULT : ROUTE.AUTH })
  }
})

router.DEFAULT_PATH = ROUTE.DEFAULT

export default router
