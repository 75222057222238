import Cookies from 'js-cookie'
import firebase from 'firebase/app'
import 'firebase/database'

const SUPPORTED_LANGUAGES = [
  { code: 'en', label: 'English' },
  { code: 'ru', label: 'Русский' }
]

const initialLanguage = (() => {
  let lang = Cookies.get('language')
  if (!lang) { // try to use browser locale
    const browserLanguage = window.navigator.userLanguage || window.navigator.language
    lang = (browserLanguage && browserLanguage.split('-')[0])
  }
  if (!SUPPORTED_LANGUAGES.map(i => i.code).includes(lang)) {
    lang = undefined // lang not supported
  }
  return lang || 'en' // fallback to "en"
})()

const _VOCABULARY = {
  'Sweet': { ru: 'Сладкий' },
  'Home': { ru: 'Домик' },
  'Yes': { ru: 'Да' },
  'No': { ru: 'Нет' },
  'Cancel': { ru: 'Отмена' },
  'Close': { ru: 'Закрыть' },
  'Save': { ru: 'Сохранить' },
  'Corridor': { ru: 'Коридор' },
  'Hall': { ru: 'Холл' },
  'Paul room': { ru: 'Пашина комната' },
  'Bedroom': { ru: 'Спальня' },
  'Living room': { ru: 'Гостиная' },
  'Alex room': { ru: 'Сашина комната' },
  'Roof': { ru: 'Крыша' },
  'Kitchen': { ru: 'Кухня' },
  'Utility room': { ru: 'Подсобная комната' },
  'Balcony': { ru: 'Балкон' },
  'Bathroom': { ru: 'Ванная' },
  'Temperature': { ru: 'Температура' },
  'Humidity': { ru: 'Влажность' },
  "Welcome to Botya's home!": { ru: 'Добро пожаловать к Боте в гости!' },
  'Please come in with Google': { ru: 'Пожалуйста, входите с помощью Google' },
  "Botya can't find requested page": { ru: 'Ботя не может найти запрашиваемую страницу' },
  'Overview': { ru: 'Обзор' },
  'Flat plan': { ru: 'План квартиры' },
  'WLAN devices': { ru: 'WLAN устройства' },
  'Activity': { ru: 'Активность' },
  'Charts': { ru: 'Графики' },
  'Exit': { ru: 'Выход' },
  'Are you sure you want to quit?': { ru: 'Вы уверены что хотите выйти?' },
  'Settings': { ru: 'Настройки' },
  'Language': { ru: 'Язык' },
  'Email': { ru: 'Электронная почта' },
  'IP address': { ru: 'IP адрес' },
  'Last updated': { ru: 'Последнее обновление' },
  'Dad': { ru: 'Пап' },
  'Mom': { ru: 'Мам' },
  'Turn on': { ru: 'Включить' },
  'Turn off': { ru: 'Выключить' },
  'Date and time': { ru: 'Дата и время' },
  'Place': { ru: 'Место' },
  'State': { ru: 'Состояние' },
  'Type': { ru: 'Тип' },
  'Presence': { ru: 'Движение' },
  'Window': { ru: 'Окно' },
  'Door': { ru: 'Дверь' },
  'Balcony door': { ru: 'Дверь на балкон' },
  'No data available': { ru: 'Нет данных' },
  'Move detected': { ru: 'Зафиксировано движение' },
  'Opened': { ru: 'Открытие' },
  'Closed': { ru: 'Закрытие' },
  'Loading data...': { ru: 'Загружаю данные...' }
}

const getVocabulary = lang => {
  const keys = Object.keys(_VOCABULARY)
  return (lang === 'en' || !SUPPORTED_LANGUAGES.map(i => i.code).includes(lang))
    ? keys.reduce((v, i) => ({ ...v, [i]: i }), {})
    : keys.reduce((v, i) => ({ ...v, [i]: _VOCABULARY[i][lang] || i }), {})
}

const [M, A] = [
  { // MUTATIONS
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_VOCABULARY: 'SET_VOCABULARY'
  },
  { // ACTIONS
    SET_LANGUAGE: 'setLanguage'
  }
]

export default {
  namespaced: true,
  state: {
    supportedLanguages: SUPPORTED_LANGUAGES,
    language: initialLanguage,
    s: getVocabulary(initialLanguage)
  },
  getters: {
    getPlaceLabel: state => place => ({
      'entrance': state.s['Corridor'],
      'office': state.s['Paul room'],
      'bedroom': state.s['Bedroom'],
      'livingroom': state.s['Living room'],
      'paulroom': state.s['Alex room'],
      'roof': state.s['Roof'],
      'kitchen': state.s['Kitchen'],
      'utilroom': state.s['Utility room'],
      'bathroom': state.s['Bathroom'],
      'hall': state.s['Hall'],
      'balcony': state.s['Balcony']
    }[place]),
    getPlaceColor: state => place => ({
      'entrance': '#607D8B', // blue-grey
      'office': '#212121', // grey
      'bedroom': '#F44336', // red
      'livingroom': '#651FFF', // deep-purple
      'paulroom': '#FF9100', // orange
      'roof': '#D81B60', // pink
      'kitchen': '#4CAF50', // green
      'utilroom': '#795548', // brown
      'bathroom': '#2196F3', // blue
      'hall': '#607D8B', // blue-grey
      'balcony': '#AEEA00' // lime
    }[place]),
    getSensorItemLabel: state => place => ({
      'presence': state.s['Presence'],
      'door': state.s['Door'],
      'window': state.s['Window'],
      'balcony': state.s['Balcony door']
    }[place]),
    getValueLabel: state => (item, value) => ({
      presence: { true: state.s['Move detected'] },
      door: { true: state.s['Opened'], false: state.s['Closed'] },
      window: { true: state.s['Opened'], false: state.s['Closed'] },
      balcony: { true: state.s['Opened'], false: state.s['Closed'] }
    }[item][value])
  },
  mutations: {
    [M.SET_LANGUAGE]: (state, language) => { state.language = language },
    [M.SET_VOCABULARY]: (state, language) => { state.s = getVocabulary(language) }
  },
  actions: {
    [A.SET_LANGUAGE]: ({ commit, state, rootState }, language) => {
      if (state.language !== language) {
        commit(M.SET_LANGUAGE, language)
        commit(M.SET_VOCABULARY, language)
        Cookies.set('language', language)
        firebase
          .database()
          .ref(`/users/${rootState.auth.user.uid}/language`)
          .set(language)
      }
    }
  }
}
