const TWO_HOUR_MILLIS = 1000 * 60 * 60 * 2 // milliseconds of two hours
const VALUE_RENDERER = {
  'temperature': t => `${(t / 100).toFixed(1)} °C`,
  'humidity': h => `${Math.round((h / 100))} %`
}

export default {
  TWO_HOUR_MILLIS,
  DAY_MILLIS: TWO_HOUR_MILLIS * 12,

  isOpened: (sensors, place, item) => {
    const sensor = sensors && sensors
      .filter(sensor => sensor.place === place && sensor.item === item)[0]
    return sensor && sensor.state && sensor.state.open
  },

  isActive: (sensors, place) => {
    const sensor = sensors && sensors
      .filter(sensor => sensor.place === place && sensor.item === 'presence')[0]
    return sensor && sensor.state && sensor.state.presence
  },

  isLeak: (sensors, place) => {
    const sensor = sensors && sensors
      .filter(sensor => sensor.place === place && sensor.item === 'leak')[0]
    return sensor && sensor.state && sensor.state.water
  },

  getValue: (sensors, place, item) => {
    const sensor = sensors && sensors
      .filter(sensor => sensor.place === place && sensor.item === item)[0]
    const value = sensor && sensor.state && sensor.state[item]
    return (VALUE_RENDERER[item] || (v => v))(value)
  },

  isLightOff: (place) => {
    return (state) => {
      try {
        const switchDevice = state.devices && state.devices
          .filter(d => d.place === place && d.lights)[0]
        return !Object.values(switchDevice.lights)[0].state.on
      } catch (ex) {
        return true // off by default
      }
    }
  },

  // isDeviceOnline: (macAddress) => {
  //   return (state) => {
  //     try {
  //       return !!state.wlanDevices && !!state.wlanDevices.filter(d => d.mac === macAddress).length
  //     } catch (ex) {
  //       return true // off by default
  //     }
  //   }
  // },

  isOutdated: (sensors, place, item, millis) => {
    const sensor = sensors && sensors
      .filter(sensor => sensor.place === place && sensor.item === item)[0]
    // if sensor was last updated more than 2 hours ago, mark value as outdated
    return !sensor || (Date.now() - sensor.state.lastupdated > (millis || TWO_HOUR_MILLIS))
  }
}
