import Vue from 'vue'
import Vuex from 'vuex'

import i18n from './i18n.js'
import auth from './auth.js'
import db from './db.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { i18n, auth, db }
})

export default store
