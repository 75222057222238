<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <v-dialog v-model='show' persistent max-width='400'>
      <v-card>
        <v-card-title class="headline">{{ $store.state.i18n.s[title] }}</v-card-title>
        <v-card-text>
          <form>
            <v-text-field
              :label='$store.state.i18n.s["Email"]'
              readonly
              :value='$store.state.auth.user.email'
            ></v-text-field>
            <v-select
              ref='language'
              @change='$store.dispatch("i18n/setLanguage", language)'
              :label='$store.state.i18n.s["Language"]'
              :items='$store.state.i18n.supportedLanguages'
              item-text='label'
              item-value='code'
              v-model='language'
            ></v-select>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-2" text @click="close()">
            {{ $store.state.i18n.s[closeButtonText] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'UserSettingsDialog',
  data () {
    return {
      title: 'Settings',
      language: this.$store.state.i18n.language,
      closeButtonText: 'Close'
    }
  },
  props: [
    'show'
  ],
  methods: {
    close () { this.$emit('close') }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.show) {
        switch (e.keyCode) {
          case 13: // Enter
            this.confirm()
            break
          case 27: // Escape
            this.reject()
            break
        }
      }
    })
  }
}
</script>
