<template>
  <v-app>
    <v-navigation-drawer
      v-if='$store.getters["auth/isAuthenticated"]'
      :clipped='true'
      permanent
      app
      :mini-variant="$vuetify.breakpoint.xsOnly"
      mini-variant-width="60"
    >
      <v-list>
        <template v-for='menuItem in menuItems'>
          <div :key='menuItem.groupName'>
            <v-list-item-subtitle :hidden="$vuetify.breakpoint.xsOnly">
              <v-subheader>{{ $store.state.i18n.s[menuItem.groupName] }}</v-subheader>
            </v-list-item-subtitle>
            <v-list-item-group color='blue'>
              <template v-for='item in menuItem.items'>
                <v-list-item
                  v-if='item.route'
                  :key='item.label'
                  :to='item.route'
                  >
                    <v-list-item-avatar>
                      <v-icon class='grey--text text--darken-2'>
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class='grey--text text--darken-2' :hidden="$vuetify.breakpoint.xsOnly">
                        {{ $store.state.i18n.s[item.label] }}
                      </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </div>
        </template>
      </v-list>

      <template v-slot:append>
        <v-layout justify-center :column="$vuetify.breakpoint.xsOnly">
          <template v-for='(userDevice) in $store.getters["db/userDevices"]'>
            <v-btn
              dark
              fab
              bottom
              left
              :color='userDevice.connected ? userDevice.color : "blue-grey lighten-2"'
              :class='userDevice.connected ? "connected" : "disconnected"'
              :key='userDevice.mac'
              style="margin-bottom: 10px; margin-right: 5px;"
            >
              <v-hover v-slot:default="{ hover }">
                <v-avatar size='48px'>
                  <v-img
                    v-bind:src='`https://www.gravatar.com/avatar/${userDevice.userIcon}`'
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-13 white--text"
                        style="height: 100%;"
                      >
                        <span>{{ $store.state.i18n.s[userDevice.owner] }}</span>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-avatar>
              </v-hover>
            </v-btn>
          </template>
        </v-layout>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      :clipped-left='true'
    >
      <v-toolbar-title class="headline text-uppercase">
        <span>{{ $store.state.i18n.s['Sweet'] }}</span>
        <span class="font-weight-light">{{ $store.state.i18n.s['Home'] }}</span>
        <span class="text-lowercase font-weight-light"> v2</span>
      </v-toolbar-title>
      <v-spacer/>
      <v-menu offset-y v-if='this.$store.getters["auth/isAuthenticated"]'>
        <template v-slot:activator="{ on }">
          <v-btn
            class='user-menu'
            v-on='on'
            text
            :icon="$vuetify.breakpoint.xsOnly"
          >
            <v-avatar size='48px'>
              <img
                :src='$store.state.auth.user.photoURL'
                :alt="($store.state.auth.user.displayName || '').split(' ').map(i => i.charAt(0)).join('').toUpperCase()"
              >
            </v-avatar>
            <span :hidden="$vuetify.breakpoint.xsOnly" class="mr-2" style="margin-left: 5px;">{{ $store.state.auth.user.displayName }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for='userMenuItem in userMenuItems'
            :key='userMenuItem.title'
            @click.stop='onUserMenuItem(userMenuItem.handler)'
          >
            <v-icon left>{{ userMenuItem.icon }}</v-icon>
            <v-list-item-title>{{ $store.state.i18n.s[userMenuItem.title] }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- Dialogs -->
    <LogoutConfigmationDialog
      :show.sync='confirmLogout'
      @reject='onLogout(false)'
      @confirm='onLogout(true)'
    />
    <UserSettingsDialog
      :show.sync='userSettings'
      @close='userSettings = false'
    />
  </v-app>
</template>

<script>
import LogoutConfigmationDialog from './views/dialogs/LogoutConfigmation'
import UserSettingsDialog from './views/dialogs/UserSettings'

export default {
  name: 'App',
  components: {
    LogoutConfigmationDialog,
    UserSettingsDialog
  },
  data () {
    return {
      confirmLogout: false,
      userSettings: false,
      userMenuItems: [
        { title: 'Settings', icon: 'fa-cog', handler: 'onSettings' },
        { title: 'Exit', icon: 'fa-sign-out-alt', handler: 'onLogout' }
      ],
      menuItems: [
        {
          groupName: 'Overview',
          items: [
            { route: 'overview', label: 'Flat plan', icon: 'fa-home' },
            { route: 'wlan', label: 'WLAN devices', icon: 'fa-wifi' },
            { route: 'log', label: 'Activity', icon: 'fa-user-shield' }
          ]
        },
        {
          groupName: 'Charts',
          items: [
            { route: 'temperature', label: 'Temperature', icon: 'fa-thermometer-half' },
            { route: 'humidity', label: 'Humidity', icon: 'fa-tint' }
          ]
        }
      ]
    }
  },
  methods: {
    onUserMenuItem (itemName) {
      this[itemName]()
    },
    onSettings () {
      this.userSettings = true
    },
    async onLogout (confirm) {
      switch (confirm) {
        case true:
          this.confirmLogout = false
          await this.$store.dispatch('auth/logout')
          this.$router.push({ path: '/auth' })
          break
        case false:
          this.confirmLogout = false
          break
        default:
          this.confirmLogout = true
          break
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.user-menu {
  height: 100% !important;
}
.disconnected {
  opacity: .6;
  * {
    filter: grayscale(1) !important;
  }
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
  span {
    margin-top: -8px;
  }
}
</style>
