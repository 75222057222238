<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <v-dialog v-model='show' persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $store.state.i18n.s[title] }}</v-card-title>
        <v-card-text>{{ $store.state.i18n.s[body] }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="green accent-4" text @click="reject()">{{ $store.state.i18n.s[rejectText] }}</v-btn>
          <v-btn color="red accent-4" text @click="confirm()">{{ $store.state.i18n.s[confirmText] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'LogoutConfirmationDialog',
  data () {
    return {
      title: 'Exit',
      body: 'Are you sure you want to quit?',
      rejectText: 'No',
      confirmText: 'Yes'
    }
  },
  props: [
    'show'
  ],
  methods: {
    reject () { this.$emit('reject') },
    confirm () { this.$emit('confirm') }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.show) {
        switch (e.keyCode) {
          case 13: // Enter
            this.confirm()
            break
          case 27: // Escape
            this.reject()
            break
        }
      }
    })
  }
}
</script>
